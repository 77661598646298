import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ASSET_PREFIX } from 'utils/constants';
import { SliderButtons } from './SliderButtons';
import { useIsMobile } from 'hooks/ui/useIsMobile';

const images = [
    {
      id: 0,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-1.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-1-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-1-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-1-thumbnail-small.webp`,
      seoText: `I don't know if I can pick a favourite part. We loved every moment! - Harriet G won a Japan trip for 2 worth £25,000`
    },
    {
      id: 1,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-2.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-2-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-2-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-2-thumbnail-small.webp`,
      seoText: 'We had a great time in the Marrakech madness. Thank you so much - Neil W won a trip for 2 to Marrakech worth £2,000'
    },
    {
      id: 2,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-31.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-31-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-31-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-31-thumbnail-small.webp`,
      seoText: `Never thought I'd get lucky winning anything. Thank you again! -  Aaron S won a Gadget Bundle worth £2,000`
    },
    {
      id: 3,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-4.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-4-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-4-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-4-thumbnail-small.webp`,
      seoText: "Fantastic day at IKEA... thanks so much - Lucy B won an IKEA gift card worth £150"
    },
    {
      id: 4,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-5.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-5-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-5-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-5-thumbnail-small.webp`,
      seoText: "We've had the BEST time. Thank you so much! - Nicola R won a trip for 2 to Barbados worth £2,000"
    },
    {
      id: 5,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-6.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-6-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-6-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-6-thumbnail-small.webp`,
      seoText: 'Thank you for giving so many people like me chances to visit these beautiful places - Lucie Z won a trip for 2 to Tulum worth £2,000'
    },
    {
      id: 6,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-7.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-7-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-7-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-7-thumbnail-small.webp`,
      seoText: "I just want to thank Daymade for the prize. Pure enjoyment at its best - Doreen N won a 2-night London trip for 2 worth £150"
    },
    {
      id: 7,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-8.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-8-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-8-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-8-thumbnail-small.webp`,
      seoText: "The trip was absolutely amazing. Zoe and I had the best time in Iceland. Truly unforgettable - James C won a trip for 2 to Iceland worth £10,000"
    },
    {
      id: 8,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-9.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-9-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-9-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-9-thumbnail-small.webp`,
      seoText: 'We have never been able to afford to take our kids abroad on holiday so this is literally life changing - Annie L won a lastminute.com gift card worth £10,000'
    },
    {
      id: 9,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-10.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-10-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-10-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-10-thumbnail-small.webp`,
      seoText: 'Think this is a brilliant and genuine site. Highly recommended - Lynda J won a bouquet of flowers worth £25'
    }
];

const imageContainerStyle: React.CSSProperties = {
  width: '100%',  
};
const carouselContainerStyle: React.CSSProperties = {
    margin: '0 auto',
    width: '100%',
    height: '100%'
};

interface IWinner {
  id: number;
  main: string;
  small: string;
  thumbnail_small: string;
  seoText: string;
}

const IMAGE_ID_KEY = 'WINNER_SPOTLIGHT_IMAGE_ID';
const IMAGE_SELECTION_TIME = 'WINNER_SPOTLIGHT_SELECTION_TIME';

export const CarouselSlider: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [selectedImage, setSelectedImage] = useState<IWinner | null>(null);

  useEffect(() => {
    const savedImageId = localStorage.getItem(IMAGE_ID_KEY);
    const savedTime = localStorage.getItem(IMAGE_SELECTION_TIME);
    const currentTime = new Date().getTime();
    const TTL_MILLISECONDS = 12 * 60 * 60 * 1000;

    if (
      savedImageId &&
      savedTime &&
      currentTime - Number(savedTime) < TTL_MILLISECONDS
    ) {
      const imageSelected = images.find((img) => img.id === Number(savedImageId)) ?? images[Math.floor(Math.random() * images.length)]
      setSelectedImage(imageSelected);
    } else {
      const randomImage = images[Math.floor(Math.random() * images.length)];
      localStorage.setItem(IMAGE_ID_KEY, randomImage.id.toString());
      localStorage.setItem(IMAGE_SELECTION_TIME, currentTime.toString());
      setSelectedImage(randomImage);
    }
  }, [images]);

  if(!selectedImage){
    return null
  }

  return (
    <div style={carouselContainerStyle}>
      <div key={selectedImage.id} style={imageContainerStyle}>
        <img
          src={!isMobile ? selectedImage.main : selectedImage.small}
          alt={selectedImage.seoText}
          style={{ width: '100%', height: '100%', padding: '4px' }}
        />
      </div>
      <style>{`
            .slick-prev:before, .slick-next:before {
            color: black;
            }
        `}</style>
    </div>
  );
};