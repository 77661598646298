import React, { useEffect, useState } from 'react';
import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import { useActivityFeedStore } from 'hooks/stores/useActivityFeedStore';
import { useRouter } from 'next/router';

// Users will be able to set these props in Studio.
interface ActivityFeedGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

const ACTIVITY_FEED_ALLOWED_PATHS = ['/test/home-dashboard', '/']

export const ActivityFeedGlobalContext = observer(
  ({ children }: React.PropsWithChildren<ActivityFeedGlobalContextProps>) => {
    const router = useRouter()

    const { displayedItems: activityFeedItems, startFetching, stopFetching } = useActivityFeedStore();


    useEffect(() => {
      (async() => {
        if (!ACTIVITY_FEED_ALLOWED_PATHS.includes(router.asPath)) {
          stopFetching();
        }else{
          startFetching()
        }
      })()
    }, [router.asPath]);

    return (
      <GlobalActionsProvider
        contextName="ActivityFeedGlobalContext"
        actions={{}}
      >
        <DataProvider
          name="activity_feed"
          data={{
            activityFeedItems,
          }}
        >
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
