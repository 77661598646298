import Subheading from "components/Subheading";


export const EmptyText: React.FC<{
  text: string;
  fontSize: string;
  strokeColor: string;
}> = ({ text, fontSize, strokeColor }) => {
  return (
    <>
      <div className="container">
        <div className="first">
          <strong id="empty-text" className="first">
            {text}
          </strong>
        </div>
        <div className="second">
          <strong id="empty-text-2" className="second">
            {text}
          </strong>
        </div>
      </div>
      <style>
        {`
            #empty-text{
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: ${strokeColor};
                -webkit-text-stroke-color: #4a4a4a;
                text-shadow: none;
                position: relative;
                letter-spacing: 5px;
                font-size: ${fontSize};
            }

            #empty-text-2 {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: ${strokeColor};
                -webkit-text-stroke-color: white;
                text-shadow: none;
                position: relative;
                letter-spacing: 5px;
                font-size: ${fontSize};
                left: 0.5px;
            }

            .first {
                position: absolute;
                z-index: 2;
            }
            .second {
                position: absolute;
                z-index: 1;

            }
        `}
      </style>
    </>
  );
};